var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    this.showtotal != "true"
      ? _c("p", [
          _vm._v(
            "\n        Hauptsaison " +
              _vm._s(this.maxSeasonPrices["HS - Hauptsaison"]) +
              "\n        "
          ),
          _c("br"),
          _vm._v(
            " Nebensaison " +
              _vm._s(this.maxSeasonPrices["NS - Nebensaison/Frühling"]) +
              "\n        "
          ),
          _c("br"),
          _vm._v(
            " Vorsaison " +
              _vm._s(this.maxSeasonPrices["VS - Vorsaison"]) +
              "\n        "
          ),
          _c("br"),
          _vm._v(
            " Endreinigung " + _vm._s(this.priceFinalCleaning) + "\n       "
          ),
          _c("br"),
          _vm._v(" "),
          this.priceLaundry != "0"
            ? _c("span", [
                _vm._v(
                  "Wäschepaket pro Person (optional) " +
                    _vm._s(this.priceLaundry)
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    this.showtotal == "true"
      ? _c("span", [
          _vm._v(
            "\n        HS " +
              _vm._s(
                this.getTotalValue(
                  this.maxSeasonPrices["HS - Hauptsaison"],
                  this.priceFinalCleaning
                )
              ) +
              "\n        NS " +
              _vm._s(
                this.getTotalValue(
                  this.maxSeasonPrices["NS - Nebensaison/Frühling"],
                  this.priceFinalCleaning
                )
              ) +
              "\n        VS " +
              _vm._s(
                this.getTotalValue(
                  this.maxSeasonPrices["VS - Vorsaison"],
                  this.priceFinalCleaning
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }