<template>
    <span>
        <p v-if="this.showtotal != 'true'">
            Hauptsaison {{this.maxSeasonPrices['HS - Hauptsaison']}}
            <br/> Nebensaison {{this.maxSeasonPrices['NS - Nebensaison/Frühling']}}
            <br/> Vorsaison {{this.maxSeasonPrices['VS - Vorsaison']}}
            <br/> Endreinigung {{this.priceFinalCleaning}}
           <br/> <span v-if="this.priceLaundry != '0'">Wäschepaket pro Person (optional) {{this.priceLaundry}}</span>
    </p>
    <span v-if="this.showtotal == 'true'">
            HS {{this.getTotalValue(this.maxSeasonPrices['HS - Hauptsaison'], this.priceFinalCleaning)}}
            NS {{this.getTotalValue(this.maxSeasonPrices['NS - Nebensaison/Frühling'], this.priceFinalCleaning)}}
            VS {{this.getTotalValue(this.maxSeasonPrices['VS - Vorsaison'], this.priceFinalCleaning)}}
        </span>
    </span>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-tab',
    	props: {
    		pricelist: String,
    		showtotal: String
    	},
    	data: function() {
    		return {
    			priceFinalCleaning: String,
    			priceLaundry: String,
    			maxSeasonPrices: {},

    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		//console.log('Pricelist::', this.pricelist);
    		var i, j;
    		this.priceLaundry = '0';
    		for (i = 0; i < pricelist.length; i++) {
    			if (pricelist[i].service.type && pricelist[i].service.type === 'FINALCLEANING') {
    				//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				if (this.showtotal == 'true') {
    					this.priceFinalCleaning = (pricelist[i].price / 100);
    				} else {
    					this.priceFinalCleaning = (pricelist[i].price / 100).toLocaleString('de-DE', {
    						minimumFractionDigits: 2,
    						maximumFractionDigits: 2
    					}) + ' €';
    				}
    			}
    			//console.log('service type::::::' + pricelist[i].service.type);
    			//console.log('service id::::::' + pricelist[i].service._id);

    			if (pricelist[i].service.type && pricelist[i].service._id === 47934) {
    				//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				//console.log('setting laundry price....................');
    				if (this.showtotal == 'true') {
    					this.priceLaundry = (pricelist[i].price / 100);
    				} else {
    					this.priceLaundry = (pricelist[i].price / 100).toLocaleString('de-DE', {
    						minimumFractionDigits: 2,
    						maximumFractionDigits: 2
    					}) + ' €';
    				}
    			}
    			if (pricelist[i].service.type && pricelist[i].service.type === 'RENT') {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					//console.log('line' + j + " ::::: " + JSON.stringify(pricelist[i].lines[j], 4));
    					//console.log('inside loop date::' + pricelist[i].lines[j].season);

    					if (["HS - Hauptsaison", "VS - Vorsaison", "NS - Nebensaison/Frühling", "NS - Nebensaison/Herbst"].includes(pricelist[i].lines[j].season)) {
    						//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].lines[j].value);
    						const season = pricelist[i].lines[j].season;
    						let valueInEuro;
    						if (this.showtotal == 'true') {
    							valueInEuro = (pricelist[i].lines[j].value / 100);
    						} else {
    							valueInEuro = (pricelist[i].lines[j].value / 100).toLocaleString('de-DE', {
    								minimumFractionDigits: 2,
    								maximumFractionDigits: 2
    							}) + ' €';
    						}
    						//console.log('season::' + season);
    						//console.log('valueInEur:' + valueInEuro);
    						if (!this.maxSeasonPrices[season] || valueInEuro > this.maxSeasonPrices[season]) {
    							this.maxSeasonPrices[season] = valueInEuro;
    						}
    					}
    				}


    			}

    		}
    		//console.log('this.this.priceLaundry::::::' + this.priceLaundry);
    		//console.log('this.maxSeasonPrices[season]:::' + JSON.stringify(this.maxSeasonPrices, 4));
    	},

    	methods: {
    		getTotalValue: function(rent, finalCleaning) {
    			var total = parseFloat(rent) + parseFloat(finalCleaning);
    			return total.toLocaleString('de-DE', {
    				minimumFractionDigits: 2,
    				maximumFractionDigits: 2
    			}) + ' €';
    		}

    	}
    };
</script>