import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		},

		goFavSearch: function () {
			var url = window.location.href;
			if (url.indexOf('favorites') >= 0) {
				if (url.indexOf('adults=0') >= 0) {
					window.location.href = './s#?favorites=true';
				} else {
					window.location.href = './s#?favorites=true&adults=0';
				}
			} else {
				window.location.href = './s#?favorites=true';
			}
		}



	}
};